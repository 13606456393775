::ng-deep .navCss nav
{
    background: rgb(206,32,41);
    background: linear-gradient(180deg, rgba(206,32,41,1) 0%, rgba(173,22,30,0.9167017148656338) 35%, rgba(0,0,0,0.6505952722886029) 100%);
    height: 50px;
}
::ng-deep body
{
      background-image: url("./assets/images/diamondPlate.jpg");
}
.navbar{
  z-index:1;
  

}
#navbarCollapse.navbar-collapse.collapse.show{
  font-size: 16;
  background: black;
  opacity: 90%;
  
}
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  .img {
      max-width: 100%;
  }
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    align-content: center;
    width: 100%;
  }
  .row{
      position: relative;
      align-content: center;
      align-items: center;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }
.center{
    text-align: center;
}
.container{
    text-align: center;
    color: whitesmoke;
    margin:0;
    background-size: 100%;
    background-position: center;
    height: fit-content;
    top:0;
    left:0;
    max-width: auto;
    background-image: url(./assets/images/fullgarage.jpg);
    background-repeat: no-repeat;
    border: 2px solid;
    margin: auto;
    width: 100%;    border-color:whitesmoke;
    background-size: cover;
    font-family: "stencil";
  src: local("stencil"), url(./assets/fonts/36103_Stencil2.ttf) format("truetype");

}
h3{
    align-content: center;
}
p{
    padding-left: 5%;
}
.column{
    position: relative;
}
ul{
    padding-left: 10%;
    text-align: left;
}
p{
    text-align: left;
}
h3{
    padding-left: 10%;
    font-size: 14pt;
    text-align: left;
    font-weight: bold;
}
h2, h1{
    padding-bottom: 5%;
    font-weight: bold;
}
@font-face {
  font-family: "stencil";
  src: local("stencil"), url(./assets/fonts/36103_Stencil2.ttf) format("truetype");
}
h1{
  padding: 10%;

  font-family: "stencil";
  color: #fff;
  text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
  font-size: 40pt;
    text-align: center;

}
h2{
  padding-bottom: 0%;
  font-family: "stencil";
  color: #fff;
  text-shadow: 3px 0 0 #000, 0 -3px 0 #000, 0 3px 0 #000, -3px 0 0 #000;
  font-size: 26pt;
  text-align: center;

}
h3{
  padding-bottom: 0%;
  font-family: "stencil";
  color: #fff;
  text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
  font-size: 22pt;
  text-align: center;
}
.logo {
  font-size: 18pt;
  font-family: "stencil";
  color: #fff;
  text-shadow: 2px 0 0 #000, 0 -1px 0 #000, 0 2px 0 #000, -1px 0 0 #000;
}
a{
  color: white;
}

